<template>
  <div class="">
    <PageTitle
      :items="{
        title: '学生',
        subTitle: '学生一覧',
        tabs: [
          {
            title: 'アクティブ',
            count: getStudentCounts.active ? getStudentCounts.active : 0,
            action: showActiveTables,
            selected: $route.query.status ? $route.query.status == 1 : false
          },
          {
            title: 'インアクティブ',
            count: getStudentCounts.inactive ? getStudentCounts.inactive : 0,
            action: showNotActiveTables,
            selected: $route.query.status ? $route.query.status == 2 : false
          },

          {
            title: '退会済',
            count: getStudentCounts.cancelled ? getStudentCounts.cancelled : 0,
            action: showNotMembershipCancelledTables,
            selected: $route.query.status ? $route.query.status == 3 : false
          }
        ],
        buttons: [
          {
            title: 'CSVエクスポート',
            action: downloadCsv,
            class: 'bg-white text-ff862f',
            others: {
              outlined: true
            }
          }
        ]
      }"
    ></PageTitle>

    <SearchStudent
      @search-table="searchTable"
      :initial="search"
      class="mb-6"
    ></SearchStudent>
    <DataTable
      class="school-table"
      :headers="headers"
      :items="getAllStudent"
      :total-records="totalRecords ? totalRecords : 0"
      :number-of-pages="totalPages ? totalPages : 0"
      :loading="loading"
      ref="pagination"
      :page="$route.query.page ? parseInt($route.query.page) : 1"
      @click:row="
        $router.push({
          name: 'StudentProfile',
          params: { id: $event.id }
        })
      "
      @update:options="updateTable"
    >
      <template v-slot:[`item.is_admin_read`]="{ item }">
        <v-sheet
          v-if="item.is_admin_read === 0"
          width="6px"
          height="6px"
          class="rounded-circle bg-e14d56"
        ></v-sheet>
      </template>
      <template v-slot:[`item.student_internal_id`]="{ item }">
        <span class="d-block">
          {{ item.student_internal_id }}
        </span>
      </template>
      <template v-slot:[`item.first_name`]="{ item }">
        <span> {{ item.family_name }} {{ item.first_name }} </span>
      </template>

      <template v-slot:[`item.family_name_furigana`]="{ item }">
        <span
          >{{ item.family_name_furigana }} {{ item.first_name_furigana }}</span
        >
      </template>

      <template v-slot:[`item.graduate_year`]="{ item }">
        <span>{{ item.graduate_year }}/{{ item.graduate_month }}</span>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{ formatDate(item.created_at) }}</span>
      </template>
    </DataTable>
  </div>
</template>
<script>
import SearchStudent from '@/components/ui/SearchStudent'
import DataTable from '@/components/ui/DataTable'
import { mapGetters } from 'vuex'

export default {
  components: { SearchStudent, DataTable },
  data() {
    return {
      headers: [
        {
          text: '',
          value: 'is_admin_read',
          width: '2.41%',
          align: 'left',
          class: ['pa-0'],
          sortable: false
        },
        {
          text: 'ID',
          value: 'student_internal_id',
          width: '8.23%',
          align: 'left',
          class: ['pa-0'],
          sortable: false
        },
        {
          text: '学生名',
          width: '12.9%',
          value: 'first_name',
          class: ['pa-0'],
          align: 'left',
          sortable: false
        },
        {
          text: 'フリガナ',
          width: '15.8%',
          value: 'family_name_furigana',
          class: ['pa-0'],
          align: 'left',
          sortable: false
        },
        {
          text: '大学名',
          value: 'education_facility.name',
          width: '29.26%',
          align: 'left',
          class: ['pa-0'],
          sortable: false
        },
        {
          text: '卒業予定',
          width: '15.3%',
          value: 'graduate_year',
          align: 'left',
          class: ['pa-0'],
          sortable: false
        },
        {
          text: '登録日',
          width: '10.1%',
          value: 'created_at',
          align: 'left',
          class: ['pa-0'],
          sortable: true
        }
      ],
      search: '',
      initialLoad: true,
      loading: false,
      items: [],
      timeout: null,
      userSearchedInput: ''
    }
  },
  created() {
    this.search = this.$route.query.search ?? null
    this.getDataFromApi(this.$route.query)
  },
  mounted() {},
  computed: {
    ...mapGetters([
      'getAllStudent',
      'getStudentPagination',
      'getStudentCsvData',
      'getStudentCounts'
    ]),
    totalRecords() {
      return this.getStudentPagination?.records_total || 0
    },
    totalPages() {
      return this.getStudentPagination?.total_pages || 0
    }
  },
  methods: {
    resetPagination() {
      this.$refs.pagination.currentPage = 1
      ++this.$refs.pagination.updatePaginate
    },
    showActiveTables() {
      this.updateRoute({
        page: 1,
        status: 1
      })
      this.resetPagination()
    },
    formatDate(date) {
      if (!date) {
        return '----/--/--'
      }
      return this.$moment(date).format('YYYY/MM/DD')
    },
    showNotActiveTables() {
      this.updateRoute({
        page: 1,
        status: 2
      })
      this.resetPagination()
    },

    showNotMembershipCancelledTables() {
      this.updateRoute({
        page: 1,
        status: 3
      })
      this.resetPagination()
    },
    async updateRoute(e = undefined) {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
      this.timeout = setTimeout(async () => {
        let data = {}
        data.sort_by_order = e?.sortDesc
          ? e?.sortDesc[0]
            ? 'asc'
            : 'desc'
          : 'desc'
        data.sort_by = e?.sortBy
          ? e?.sortBy[0]
            ? e.sortBy[0]
            : 'created_at'
          : 'created_at'
        data.page = e?.page ?? this.$route.query.page ?? 1
        data.status = e?.status ?? this.$route.query.status ?? 1
        data.paginate = 25
        let updatedRouteQueryParams = {
          ...this.$route.query,
          ...data
        }
        updatedRouteQueryParams.search =
          e?.search ?? this.$route.query.search ?? ''

        await this.$router
          .replace({
            name: this.$route.name,
            query: updatedRouteQueryParams
          })
          .then(() => {
            this.initialLoad = true
            this.getDataFromApi(this.$route.query)
          })
      }, 100)
    },
    async downloadCsv() {
      await this.$store.dispatch('STUDENT_CSV')
      let fileUrl = window.URL.createObjectURL(
        new Blob([this.getStudentCsvData], {
          type: 'text/plain;charset=UTF-8'
        })
      )
      let fileLink = document.createElement('a')

      fileLink.href = fileUrl
      fileLink.setAttribute(
        'download',
        `学生情報_${this.$moment().format('YYYYMMDD')}.csv`
      )
      document.body.appendChild(fileLink)
      fileLink.click()
      document.body.removeChild(fileLink)
    },
    async toggleTableEnteries(index) {
      this.selectors[index].selected = true
      await this.getDataFromApi()
    },
    searchTable(search) {
      if (!search) {
        this.updateRoute({ search: '', page: 1 })
      } else {
        this.updateRoute({ search, page: 1 })
      }
      this.initialLoad = true
      this.resetPagination()
    },

    async getDataFromApi(e = undefined) {
      this.loading = true
      let data = {}
      data.sort_by_order = e?.sortDesc
        ? e?.sortDesc[0]
          ? 'asc'
          : 'desc'
        : 'desc'
      data.sort_by = e?.sortBy
        ? e?.sortBy[0]
          ? e.sortBy[0]
          : 'created_at'
        : 'created_at'
      data.page = e?.page ? e.page : 1
      data.paginate = 25
      data.status = e?.status ? e.status : 1
      data.search = e?.search ?? ''

      await this.$store
        .dispatch('STUDENT_GET_ALL', data)
        .then(() => {
          if (this.initialLoad) this.initialLoad = false
        })
        .finally(() => (this.loading = false))
    },
    updateTable(e) {
      if (!this.initialLoad) {
        // this.getDataFromApi(e)
        this.updateRoute({ ...e, search: this.search })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.student-list ::v-deep {
  tbody {
    tr {
      td {
        padding: 0px !important;
      }
    }
  }
}
</style>

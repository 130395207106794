var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('PageTitle',{attrs:{"items":{
      title: '学生',
      subTitle: '学生一覧',
      tabs: [
        {
          title: 'アクティブ',
          count: _vm.getStudentCounts.active ? _vm.getStudentCounts.active : 0,
          action: _vm.showActiveTables,
          selected: _vm.$route.query.status ? _vm.$route.query.status == 1 : false
        },
        {
          title: 'インアクティブ',
          count: _vm.getStudentCounts.inactive ? _vm.getStudentCounts.inactive : 0,
          action: _vm.showNotActiveTables,
          selected: _vm.$route.query.status ? _vm.$route.query.status == 2 : false
        },

        {
          title: '退会済',
          count: _vm.getStudentCounts.cancelled ? _vm.getStudentCounts.cancelled : 0,
          action: _vm.showNotMembershipCancelledTables,
          selected: _vm.$route.query.status ? _vm.$route.query.status == 3 : false
        }
      ],
      buttons: [
        {
          title: 'CSVエクスポート',
          action: _vm.downloadCsv,
          class: 'bg-white text-ff862f',
          others: {
            outlined: true
          }
        }
      ]
    }}}),_c('SearchStudent',{staticClass:"mb-6",attrs:{"initial":_vm.search},on:{"search-table":_vm.searchTable}}),_c('DataTable',{ref:"pagination",staticClass:"school-table",attrs:{"headers":_vm.headers,"items":_vm.getAllStudent,"total-records":_vm.totalRecords ? _vm.totalRecords : 0,"number-of-pages":_vm.totalPages ? _vm.totalPages : 0,"loading":_vm.loading,"page":_vm.$route.query.page ? parseInt(_vm.$route.query.page) : 1},on:{"click:row":function($event){return _vm.$router.push({
        name: 'StudentProfile',
        params: { id: $event.id }
      })},"update:options":_vm.updateTable},scopedSlots:_vm._u([{key:"item.is_admin_read",fn:function(ref){
      var item = ref.item;
return [(item.is_admin_read === 0)?_c('v-sheet',{staticClass:"rounded-circle bg-e14d56",attrs:{"width":"6px","height":"6px"}}):_vm._e()]}},{key:"item.student_internal_id",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.student_internal_id)+" ")])]}},{key:"item.first_name",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.family_name)+" "+_vm._s(item.first_name)+" ")])]}},{key:"item.family_name_furigana",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.family_name_furigana)+" "+_vm._s(item.first_name_furigana))])]}},{key:"item.graduate_year",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.graduate_year)+"/"+_vm._s(item.graduate_month))])]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }